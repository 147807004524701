// extracted by mini-css-extract-plugin
export var calloutButton = "LocationsIndexSidebarCallout__calloutButton__nsLT5";
export var calloutContainer = "LocationsIndexSidebarCallout__calloutContainer__Ewy0x";
export var calloutTitle = "LocationsIndexSidebarCallout__calloutTitle__ZuTvQ";
export var column = "LocationsIndexSidebarCallout__column__VB7Vd";
export var flex = "LocationsIndexSidebarCallout__flex___koxJ";
export var flexColumn = "LocationsIndexSidebarCallout__flexColumn__oQEWv";
export var gap1 = "LocationsIndexSidebarCallout__gap1__q3w81";
export var gap2 = "LocationsIndexSidebarCallout__gap2__kZTQU";
export var gap3 = "LocationsIndexSidebarCallout__gap3__KP9Aj";
export var gap4 = "LocationsIndexSidebarCallout__gap4__OzDYJ";
export var gap5 = "LocationsIndexSidebarCallout__gap5__UQOZS";
export var row = "LocationsIndexSidebarCallout__row__eHn36";