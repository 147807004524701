// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconEdit from "../../../../styleguide/icons/IconEdit.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as IconReset from "../../../../styleguide/icons/IconReset.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_City from "../../../../routes/common/Routes_City.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Api_Provider from "../../../../api/providers/Api_Provider.res.js";
import * as IconDownload from "../../../../styleguide/icons/IconDownload.res.js";
import * as Routes_State from "../../../../routes/common/Routes_State.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexHeaderScss from "./LocationsIndexHeader.scss";

var css = LocationsIndexHeaderScss;

var initialState_providers = [];

var initialState_providerIds = [];

var initialState = {
  search: "",
  providerSearch: "",
  status: "FetchingProviders",
  providers: initialState_providers,
  providerIds: initialState_providerIds
};

function LocationsIndexHeader(props) {
  var reset = props.reset;
  var __providersFilterShown = props.providersFilterShown;
  var cityId = props.cityId;
  var stateId = props.stateId;
  var countryId = props.countryId;
  var breadcrumbs = props.breadcrumbs;
  var userRole = props.userRole;
  var updateProviderIds = props.updateProviderIds;
  var updateSearch = props.updateSearch;
  var providersFilterShown = __providersFilterShown !== undefined ? __providersFilterShown : true;
  var breadcrumbs$1 = breadcrumbs !== undefined ? breadcrumbs : [
      {
        linkName: "Home",
        linkPath: "/"
      },
      {
        linkName: "All Data Centers",
        linkPath: Routes_Location.index
      }
    ];
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformProviderSearch",
                _0: param.state.providerSearch
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Provider.fetchProvidersFilter(true, state.providerSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "LocationsIndexHeader",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "LocationsIndexHeader.make"
                                          }, "FailProvidersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProvidersFetch");
                                    }));
                            })
                        };
              case "FailProvidersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            search: state.search,
                            providerSearch: state.providerSearch,
                            status: "FailedProvidersFetch",
                            providers: state.providers,
                            providerIds: state.providerIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            search: "",
                            providerSearch: "",
                            status: state.status,
                            providers: state.providers,
                            providerIds: []
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviders");
                              reset();
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProvidersFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            search: state.search,
                            providerSearch: state.providerSearch,
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            providers: res.providers,
                            providerIds: state.providerIds
                          }
                        };
              case "UpdateSearchInput" :
                  var input = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            search: input,
                            providerSearch: state.providerSearch,
                            status: state.status,
                            providers: state.providers,
                            providerIds: state.providerIds
                          },
                          _1: updateSearch(input)
                        };
              case "UpdateProviderSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            search: state.search,
                            providerSearch: action._0,
                            status: state.status,
                            providers: state.providers,
                            providerIds: state.providerIds
                          },
                          _1: searchProviders
                        };
              case "PerformProviderSearch" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProviders") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.providerSearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              search: state.search,
                              providerSearch: state.providerSearch,
                              status: "FetchingProviders",
                              providers: state.providers,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "UpdateProviderIds" :
                  var ids = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            search: state.search,
                            providerSearch: state.providerSearch,
                            status: state.status,
                            providers: state.providers,
                            providerIds: ids
                          },
                          _1: updateProviderIds(ids)
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProviders");
        }), []);
  var query = "query=" + encodeURIComponent(state.search);
  var countryQ = countryId !== undefined ? "&country_id=" + ID.toString(Caml_option.valFromOption(countryId)) : "";
  var stateQ = stateId !== undefined ? "&state_id=" + ID.toString(Caml_option.valFromOption(stateId)) : "";
  var cityQ = cityId !== undefined ? "&city_id=" + ID.toString(Caml_option.valFromOption(cityId)) : "";
  var match$1 = state.providerIds;
  var providers = match$1.length !== 0 ? "&" + (function (__x) {
          return Js_array.joinWith("&", __x);
        })(Belt_Array.map(state.providerIds, (function (id) {
                return "provider_ids[]=" + ID.toString(id);
              }))) : "";
  var match$2 = state.search;
  var match$3 = state.providerSearch;
  var match$4 = state.providerIds;
  var tmp;
  var exit = 0;
  if (match$2 === "" && match$3 === "" && match$4.length === 0) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx("a", {
          children: JsxRuntime.jsx(IconReset.make, {
                title: "Reset Filters",
                size: "LG",
                color: "LighterGray"
              }),
          className: css.reset,
          href: "#",
          onClick: (function (param) {
              dispatch("Reset");
            })
        });
  }
  var tmp$1;
  tmp$1 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
          href: "/api/v1/locations/export?" + query + providers + countryQ + stateQ + cityQ,
          targetBlank: true,
          children: JsxRuntime.jsx(IconDownload.make, {
                title: "Download",
                size: "SM",
                color: "LighterGray"
              })
        }) : null;
  var tmp$2;
  if (countryId !== undefined) {
    if (stateId !== undefined) {
      if (cityId !== undefined) {
        var tmp$3;
        tmp$3 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
                href: Routes_City.Dashboard.edit(Caml_option.valFromOption(cityId)),
                children: JsxRuntime.jsx(IconEdit.make, {
                      title: "Edit City",
                      size: "SM",
                      color: "LighterGray"
                    })
              }) : null;
        tmp$2 = JsxRuntime.jsx("div", {
              children: tmp$3,
              className: css.editIcon
            });
      } else {
        var tmp$4;
        tmp$4 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
                href: Routes_State.Dashboard.edit(Caml_option.valFromOption(stateId)),
                children: JsxRuntime.jsx(IconEdit.make, {
                      title: "Edit State",
                      size: "SM",
                      color: "LighterGray"
                    })
              }) : null;
        tmp$2 = JsxRuntime.jsx("div", {
              children: tmp$4,
              className: css.editIcon
            });
      }
    } else if (cityId !== undefined) {
      tmp$2 = null;
    } else {
      var tmp$5;
      tmp$5 = userRole === "Admin" ? JsxRuntime.jsx(A.make, {
              href: Routes_Country.Dashboard.edit(Caml_option.valFromOption(countryId)),
              children: JsxRuntime.jsx(IconEdit.make, {
                    title: "Edit Country",
                    size: "SM",
                    color: "LighterGray"
                  })
            }) : null;
      tmp$2 = JsxRuntime.jsx("div", {
            children: tmp$5,
            className: css.editIcon
          });
    }
  } else {
    tmp$2 = null;
  }
  var tmp$6;
  if (providersFilterShown) {
    var match$5 = state.status;
    var exit$1 = 0;
    if (typeof match$5 !== "object" && match$5 !== "FetchingProviders") {
      tmp$6 = null;
    } else {
      exit$1 = 1;
    }
    if (exit$1 === 1) {
      var x = state.providerIds.length;
      tmp$6 = JsxRuntime.jsxs(Dropdown.make, {
            className: css.providersDropdown,
            children: [
              JsxRuntime.jsx(Dropdown.Trigger.make, {
                    className: css.dropdownTrigger,
                    iconSize: "XS",
                    iconColor: "Gray",
                    children: x !== 0 ? (
                        x !== 1 ? String(x) + " Providers" : "1 Provider"
                      ) : "All Providers"
                  }),
              JsxRuntime.jsxs(Dropdown.Body.make, {
                    position: {
                      TAG: "Below",
                      _0: "RightEdge"
                    },
                    className: css.dropdownBody,
                    children: [
                      JsxRuntime.jsx(SearchField.make, {
                            id: "providers-search",
                            value: state.providerSearch,
                            placeholder: "Provider Name",
                            inputClassName: css.providersSearch,
                            onChange: (function ($$event) {
                                dispatch({
                                      TAG: "UpdateProviderSearchInput",
                                      _0: $$event.target.value
                                    });
                              }),
                            showSearchIcon: false
                          }),
                      Belt_Array.map(state.providers, (function (provider) {
                              var id = "location-search-filter--provider-" + ID.toString(provider.id);
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Checkbox.make, {
                                                  id: id,
                                                  size: "SM",
                                                  checked: (function (__x) {
                                                        return Js_array.findIndex((function (value) {
                                                                      return ID.toString(value) === ID.toString(provider.id);
                                                                    }), __x);
                                                      })(state.providerIds) > -1,
                                                  onChange: (function ($$event) {
                                                      var checked = $$event.target.checked;
                                                      var providerIds = checked ? Belt_Array.concat(state.providerIds, [provider.id]) : Belt_Array.keep(state.providerIds, (function (value) {
                                                                return ID.toString(value) !== ID.toString(provider.id);
                                                              }));
                                                      dispatch({
                                                            TAG: "UpdateProviderIds",
                                                            _0: providerIds
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: id,
                                                  className: css.label,
                                                  children: provider.name
                                                })
                                          ],
                                          className: css.dropdownBodyRow
                                        }, id);
                            }))
                    ]
                  })
            ]
          });
    }
    
  } else {
    tmp$6 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Breadcrumb.make, {
                              breadcrumbLinks: breadcrumbs$1,
                              wrapperContainerClassName: css.breadcrumbContainer
                            }),
                        JsxRuntime.jsxs(H1.make, {
                              className: css.title,
                              children: [
                                props.title + " ",
                                JsxRuntime.jsx("span", {
                                      children: "(" + String(props.locationsCount) + ")",
                                      className: css.locationsCount
                                    })
                              ]
                            })
                      ],
                      className: css.titleContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp,
                        JsxRuntime.jsx("div", {
                              children: tmp$1,
                              className: css.downloadIcon
                            }),
                        tmp$2,
                        JsxRuntime.jsx(SearchField.make, {
                              id: "locations-search",
                              value: state.search,
                              placeholder: "Name, city, state, country...",
                              inputClassName: css.searchField,
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        tmp$6
                      ],
                      className: css.filtersContainer
                    })
              ],
              className: css.headerContainer
            });
}

var make = LocationsIndexHeader;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
