// extracted by mini-css-extract-plugin
export var column = "LocationsIndexBookACallSection__column__To3zW";
export var flex = "LocationsIndexBookACallSection__flex__dtKVN";
export var flexColumn = "LocationsIndexBookACallSection__flexColumn__svE2E";
export var gap1 = "LocationsIndexBookACallSection__gap1__tmlwX";
export var gap2 = "LocationsIndexBookACallSection__gap2__DKY03";
export var gap3 = "LocationsIndexBookACallSection__gap3__aNYNW";
export var gap4 = "LocationsIndexBookACallSection__gap4__ZUWAZ";
export var gap5 = "LocationsIndexBookACallSection__gap5__s_FTi";
export var heading = "LocationsIndexBookACallSection__heading__gwQby";
export var row = "LocationsIndexBookACallSection__row__sfAGf";
export var wrapper = "LocationsIndexBookACallSection__wrapper__doKOk";